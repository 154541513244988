import React from 'react';
import { Box, Typography } from '@mui/material';

const AppFooter = () => (
  <Box
    component="footer"
    tabIndex={0}
    sx={{
      textAlign: 'center',

      bottom: 0,
      width: '100%',
      p: 2, // padding inside the footer, adjust as needed
      bgcolor: 'gray', // theme color for the footer background
      // Add any additional styling you need here
    }}
  >
    <Typography variant="body2" color="black">
      Swarthmore A11yGator Copyright &copy; {new Date().getFullYear()}
    </Typography>
  </Box>
);

export default AppFooter;
