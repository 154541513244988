import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, TextField, Button, Grid, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder'; 
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const AppHeader = ({ onSearch, jsonData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();
  let preventHideDropdown = false;
  const handleBlur = () => {
    // Set a timeout to delay the execution of setShowSuggestions(false)
    setTimeout(() => {
      if (!preventHideDropdown) {
        setShowSuggestions(false);
      }
    }, 1000); // 100ms delay
  };
  
  const handleFocus = () => {
    preventHideDropdown = false;
    setShowSuggestions(true);
  };
  useEffect(() => {
    // Function to filter suggestions based on search query
    const filterSuggestions = () => {
      if (searchQuery.length === 0) {
        setSuggestions([]);
        setShowSuggestions(false);
        return;
      }

      const searchLowerCase = searchQuery.toLowerCase();
      let newSuggestions = [];

      const traverse = (data) => {
        data.forEach((item) => {
          // Check for 'course' type and if it matches the search query
          if (item.type === "course" && item.name.toLowerCase().includes(searchLowerCase)) {
            newSuggestions.push({ name: item.name, icon: <FolderIcon /> });
          }
          // Also check for 'coursefolder' type and if it matches the search query
          if (item.type === "coursefolder" && item.name.toLowerCase().includes(searchLowerCase)) {
            newSuggestions.push({ name: item.name, icon: <InsertDriveFileIcon /> });
          }
          // If the item has children, recursively traverse them
          if (item.children && item.children.length > 0) {
            traverse(item.children);
          }
        });
      };
      

      traverse(jsonData); // Call the traverse function to filter suggestions
      setSuggestions(newSuggestions);
      setShowSuggestions(true);
    };

    filterSuggestions();
  }, [searchQuery, jsonData]);

  const handleSearch = (suggestion = null) => {
    const query = suggestion ? suggestion.name : searchQuery;
    onSearch(query);
    setShowSuggestions(false);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleHomeClick = () => {
    navigate('/');
    window.location.reload();
  };

  

  return (
    <AppBar position="relative" sx={{ zIndex: 100, backgroundColor: '#b43135' }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item xs>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Focusable Logo */}
              <IconButton
                onClick={handleHomeClick}
                sx={{
                  borderRadius: '20%',
                  padding: '0px',
                  '&:focus': {
                    outline: '2px solid #004FBD', // A gold outline for focus
                  },
                }}
                aria-label="Swarthmore accessible document repository logo"
              >
                <img
                  src="https://sid.swarthmore.edu/idp/images/swat-logo2.png"
                  alt="Company logo"
                  style={{ width: '100px', height: 'auto', borderRadius: '20%' }}
                />
              </IconButton>
              {/* Home Button */}
              <IconButton
  edge="start"
  color="inherit"
  aria-label="home button"
  onClick={handleHomeClick}
  sx={{
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    '&:focus': {
      outline: '2px solid #004FBD', // Consistent focus style
      outlineOffset: '2px', // Optional: to offset the outline a bit from the element
      borderRadius: '0', // No border-radius for the outline
    },
    '& .home-text': {
      fontSize: '0.9rem',
      marginLeft: '8px',
    }
  }}
>
      <HomeIcon />
      <span className="home-text">Home</span>
    </IconButton>


              
            </Box>
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleEnterKey}
              onBlur={handleBlur}
              onFocus={handleFocus}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              sx={{
                backgroundColor: "white",
                borderRadius: "5px",
                mx: 1,
                width: "100%",
                '& .MuiInputBase-input': {
                  height: 'auto',
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  '&:focus-within': {
                    outline: '2px solid #004FBD'
                    // outline: '2px solid #FFD700', // Outline for focused state
                  },
                },
              }}
              aria-label="Search field" // Screen reader label
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                marginLeft: 1,
                height: "100%",
                '&:focus': {
                  outline: '2px solid #FFD700', // Consistent focus style
                },
              }}
              aria-label="Search" // Screen reader label

            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      {showSuggestions && (
  <Box
    sx={{
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      zIndex: 1400,
      width: '100%', // Make the width same as the parent, which should be the search bar
      maxHeight: '300px', // Maximum height before scrolling
      overflowY: 'auto', // Enable vertical scrolling
      backgroundColor: 'white', // Sets the background color to white
      border: '1px solid #ddd', // Adds a border to the modal
      borderRadius: '4px', //  rounded corners
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)', //  add a box-shadow
    }}
  >
    {suggestions.map((suggestion, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
          cursor: 'pointer',
        }}
        onClick={() => {
          preventHideDropdown = true;
          navigate(`#${suggestion.name}`); // Navigate to the suggestion
          // window.location.hash = `#${suggestion.name}`; // Or directly setting the hash
          setShowSuggestions(false); // Hide suggestions after click
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}>
          {React.cloneElement(suggestion.icon, { style: { color: 'inherit' }})}
          <Box sx={{ ml: 1 }}>{suggestion.name}</Box>
        </Box>
      </Box>
    ))}
  </Box>
)}


    </AppBar>
  );
};

export default AppHeader;
