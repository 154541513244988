// Home.jsx

// Import necessary libraries and components
import React from 'react'; // Import React library
import { Link } from 'react-router-dom'; // Import the Link component from 'react-router-dom' for navigation
import '../css/App.css'; // Import styling for the component
import NoResults from './NoResults';

// Define the Home component, which takes in 'data' as props
const Home = ({ data }) => (
    // This div wraps the entire Home component
    <div className="home">
      {/* Welcome heading */}
      {data.length !== 0 && <h1 style={{textAlign: "center", fontSize:"2rem"}}>Welcome to the Swarthmore A11yGator!</h1>}

      {/* Instruction text */}
      {data.length !== 0 && <p style={{textAlign: "center"}}>Click on a link to view the corresponding HTML file.</p>}

      {/* Mapping over 'data', which is an array of courses */}
      {data.length === 0 ? (
        <div className="no-results-container">
        <NoResults description="No search results found" />

        </div>
      ) : data.map((course, index) => (
        // This div wraps each course
        <div  key={index} tabIndex={0} aria-label={`${course.name} course-folder`} className="course">
          {/* Course name */}
          <h2 id={course.name} className="course-name">{course.name}</h2>

          {/* Mapping over the 'children' of each course, which can be course folders or HTML files */}
          {course.children.map((item, subIndex) => (
            // This div wraps each course item
            <div key={subIndex} className="course-item">
              {/* If the item is a course folder, render its children (HTML files) */}
              {item.type === 'coursefolder' ? (
                <div className="course-folder">
                  <h3 id={item.name}>{item.name}</h3> {/* Course folder name */}

                  {/* Mapping over the 'children' of each course folder, which are HTML files */}
                  {item.children.map((file, fileIndex) => (
                    // Check if the file ends with '.html', if so render a Link to the file
                    file.name.endsWith('.html') && (
                      <Link id={file.name} className="course-link" key={fileIndex} to={`/${course.name}/${item.name}/${file.name}`}>
                        {file.name} {/* HTML file name */}
                      </Link>
                    )
                  ))}
                </div>
              ) : (
                // If the item is not a course folder (i.e., it's an HTML file), render a Link to the file
                item.name.endsWith('.html') && (
                  <Link to={`/${course.name}/${item.name}`} className="course-link">
                    {item.name} {/* HTML file name */}
                  </Link>
                )
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

// Export the Home component as the default export
export default Home;
