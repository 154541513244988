import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import cheerio from 'cheerio';
import { Helmet } from 'react-helmet';
import { Typography, Breadcrumbs, Button, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const HTMLViewer = () => {
  const [content, setContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const { course, courseFolder, fileName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const url = courseFolder
      ? `${process.env.PUBLIC_URL}/${course}/${courseFolder}/${fileName}`
      : `${process.env.PUBLIC_URL}/${course}/${fileName}`;

    axios
      .get(url)
      .then((res) => {
        const fetchedContent = res.data;
        const $ = cheerio.load(fetchedContent);
        const title = $('title').text(); // Extract title
        setPageTitle(title); // Update pageTitle state
        $('title').remove();
        setContent($.html());
      })
      .catch((err) => {
        console.log(err);
        setContent('<p>Sorry, an error occurred while loading the content.</p>');
      });
  }, [course, courseFolder, fileName]);


  return (
    <div className="html-viewer" tabIndex={0} aria-label="html content">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: 'auto' }}>
        <Link to="/">
          <HomeIcon />
        </Link>
        <Link to={`/#${course}`}>
          <Typography color="textPrimary">{course}</Typography>
        </Link>
        {courseFolder && (
          <Link to={`/${course}/${courseFolder}`}>
            <Typography color="textPrimary">{courseFolder}</Typography>
          </Link>
        )}
        <Typography color="textPrimary">{fileName}</Typography>
      </Breadcrumbs>

      {/* HTML Content */}
      <Box dangerouslySetInnerHTML={{ __html: content }} />

      {/* Go Back Button */}
      <Button
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate(-1)}
        sx={{
          marginBottom: '20px',
                '&:focus': {
                  outline: '2px solid #FFD700', // Consistent focus style
                },
              }}
              aria-label="Go back to homepage" // Use sx prop for styling
        variant="contained"
        color="primary"
      >
        Go Back
      </Button>
    </div>
  );
};

export default HTMLViewer;
