import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import {
 CssBaseline, Box,
  useTheme, 
} from '@mui/material';

import Home from './Home';
import HTMLViewer from './HTMLViewer';
import AppHeader from './Header';
import AppFooter from './Footer';
import ScrollToTop from './Scroll';
import Sidebar from './Sidebar'; // Import the new Sidebar component

function App() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [selectedKey, setSelectedKey] = useState('1');
  const [collapsed, setCollapsed] = useState(false);
  const [searchResults, setSearchResults] = useState(null);


  const handleSearch = (query) => {


    const results = data.filter((course) =>
      course.name.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(results);
  };
  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/data.json`)
      .then((res) => setData(res.data.children))
      .catch((err) => console.log(err));
  }, []);

  const handleDrawerToggle = () => {
    setCollapsed(!collapsed);
  };

  const drawerWidth = 280; 

  return (
    <Router>
      <CssBaseline />
      <ScrollToTop />
      <Box  component="main" sx={{ display: 'flex' }}>
      <nav aria-label="Left Navigation" tabIndex="0" >
        <Sidebar
          data={data}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          collapsed={collapsed}
          handleDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
          theme={theme}
        />
        </nav>
        <Box component="" sx={{ flexGrow: 1, p: 0.5, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <header tabIndex="0" aria-label="Main Navigation" style={{ width: '100%', position: 'sticky', top: 0, zIndex: 1100 }}>
            <AppHeader onSearch={handleSearch} jsonData={data}/>
          </header>
          <Routes>
            <Route path="/" element={<Home data={searchResults || data} />} />
            <Route path="/:course/:fileName" element={<HTMLViewer />} />
            <Route path="/:course/:courseFolder/:fileName" element={<HTMLViewer />} />
          </Routes>
        </Box>
      </Box>

      <AppFooter />
    </Router>
  );
}

export default App;