import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const Sidebar = ({ data, selectedKey, setSelectedKey, collapsed, handleDrawerToggle, drawerWidth, theme }) => {
  const navigate = useNavigate();

  // Function to navigate and close drawer if needed
  const handleListItemClick = (courseName, key) => {
    setSelectedKey(key);
    navigate(`/#${courseName}`);
  };

  return (
    <Drawer
      variant="persistent"
      open={true}
      sx={{
        width: collapsed ? theme.spacing(7) : drawerWidth,
        '& .MuiDrawer-paper': {
          width: collapsed ? theme.spacing(7) : drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          padding: !collapsed ? "10px" : "5px",
        },
      }}
    >
      <IconButton
        onClick={handleDrawerToggle}
        aria-label={collapsed ? "Open navigation" : "Close navigation"}
        sx={{
          '&:focus': {
            outline: `2px solid #004FBD`,
            outlineOffset: 0,
          },
        }}
      >
        {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>

      <List>
        {data.map((course, index) => {
          const isSelected = selectedKey === `${index + 1}`;
          const key = `${index + 1}`;
          const listItem = (
            <ListItem
              button
              key={key}
              selected={isSelected}
              onClick={() => handleListItemClick(course.name, key)}
              component="li"
              aria-label={`${course.name} course folder`}
              role="listitem"
              sx={{
                borderRadius: theme.shape.borderRadius,
                backgroundColor: isSelected ? theme.palette.primary.dark : 'transparent',
                outline: isSelected && `3px solid #004FBD`,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '& .MuiListItemIcon-root': {
                  minWidth: collapsed ? 0 : theme.spacing(5),
                },
                '& .MuiListItemText-primary': {
                  color: isSelected ? 'black' : '#595959',
                },
                '&:focus': {
                  outline: `2px solid #004FBD`,
                  outlineOffset: 2,
                },
              }}
            >
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              {!collapsed && <ListItemText primary={course.name} />}
            </ListItem>
          );

          return collapsed ? (
            <Tooltip title={course.name} placement="right">
              {listItem}
            </Tooltip>
          ) : (
            listItem
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
