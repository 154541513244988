import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff'; // You can choose an icon that fits your design

const NoResults = ({ description }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // You can adjust this as needed
        color: theme.palette.text.secondary,
        textAlign: 'center',
        tabIndex: 0,
      }}
    >
      {/* You can use the SearchOffIcon or any other icon that represents 'no results' */}
      <SearchOffIcon sx={{ fontSize: 64 }} />
      <Typography variant="subtitle1">{description}</Typography>
    </Box>
  );
};

export default NoResults;
