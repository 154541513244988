import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { hash } = useLocation();

  useEffect(() => {
    // Function to remove highlight
    const removeHighlight = () => {
      const highlighted = document.querySelector('.highlight-target');
      if (highlighted) {
        highlighted.classList.remove('highlight-target');
      }
    };

    if (hash) {
      // Decode the hash to handle spaces and other characters
      const decodedHash = decodeURIComponent(hash.slice(1));

      const elem = document.getElementById(decodedHash);

      if (elem) {
        // Scroll to the element
        const yPosition = elem.getBoundingClientRect().top + window.pageYOffset - 70;
        window.scrollTo({ top: yPosition, behavior: 'instant' });

        // Highlight the element
        elem.classList.add('highlight-target');

        // Set up a one-time event listener to remove the highlight when clicking anywhere on the page
        window.addEventListener('click', removeHighlight, { once: true });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // Ensure any previous highlights are removed if hash is cleared
      removeHighlight();
    }

    // Cleanup the event listener when component unmounts or hash changes
    return () => {
      window.removeEventListener('click', removeHighlight);
    };
  }, [hash]);

  return null;
}

export default ScrollToTop;
